<template>
  <div class="page">
    <div class="member">
      <!-- 头部 -->
      <div class="head">
        <img src="../../assets/images/slices/imperial.png" alt="" />
        <div>
          <p>线上会员 助你了解更多</p>
          <p>也在会员 多项超实用特权,总有一项能帮到你</p>
          <p class="kaitong" @click="open(shapList[0])">立即开通</p>
        </div>
      </div>
      <!-- 充值会员 -->
      <div class="content">
        <!-- 脱单光荣榜左侧 -->
        <div class="left">
          <div class="honour">
            <p class="title">脱单<span>光荣榜</span></p>
            <div class="block">
              <Swiper />
            </div>
          </div>
          <!-- 2 -->
          <div class="ta">
            <p class="remove">开通也在会员,快去联系TA们吧</p>
            <div v-for="(item, index) in huiyuan.slice(0, 4)" :key="index">
              <div class="ta-avatar">
                <img :src="item.Img" alt="" />
              </div>
              <div class="means">
                <p>{{ item.nickName }}</p>
                <p class="age">
                  <span v-if="item.age">{{ item.age + "岁" }}</span>
                  &nbsp;
                  <span v-if="item.height">{{ item.height + "cm" }}</span>
                </p>
                <p class="dubai">
                  <span v-if="item.introduce != null">{{
                    item.introduce.substring(0, 15) +
                    (item.introduce.length >= 14 ? "..." : "")
                  }}</span>
                  <span v-else>
                    {{
                      item.sex == "0" ? "他" : "她"
                    }}很适合你哦，赶紧去看看吧！
                  </span>
                </p>
              </div>
            </div>
          </div>
          <!-- 3问答 -->
          <div class="qustion">
            <div class="title">也在问答</div>
            <div class="contentLL" :class="hi == 1 ? '' : 'hidden'">
              <p class="question">已成功付费，但没有显示也在会员图标怎么办？</p>
              <p class="answer">
                可将成功支付截图反馈给客服人员，让客服人员协助
              </p>
              <p class="question">是否可以随时退费？？</p>
              <p class="answer">不能</p>
              <p class="question">开通也在会员后成功率更高？</p>
              <p class="answer">
                也在会员是相亲诚意的象征，在站内有更多的曝光机会，同时也会得到14项特权帮助
              </p>
              <div class="key" v-show="morequstion">
                <p class="question">开通也在会员后成功率更高？</p>
                <p class="answer">
                  也在会员是相亲诚意的象征，在站内有更多的曝光机会，同时也会得到14项特权帮助
                </p>
              </div>
            </div>
            <div class="showMore" @click="hi == 1 ? (hi = 0) : (hi = 1)">
              <span>{{ hi == 1 ? "收起" : "展开更多" }}</span>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="right">
          <div class="huiyuan">
            <div
              class="diamond mouth"
              v-for="(item, index) in shapList.slice(0, 3)"
              :key="index"
              @click="clickbuy(item, index)"
              :class="{ active: buyIndex == index }"
            >
              <div>
                <img src="../../assets/images/slices/jewel.png" alt="" />
              </div>
              <div class="detaile">
                <p>{{ item.goodName }}</p>
                <p>{{ item.goodIntroduce }}</p>
                <p></p>
                <p>
                  超值会员价 <span>{{ Number(item.goodPrice) / 100 }}</span> 元
                </p>
                <p class="btn-kt" @click="open(item)">立即开通</p>
              </div>
            </div>
          </div>

          <!-- 会员权益 -->
          <div class="on-line">
            <div class="line-vip" v-if="iSvip == 1">
              你的也在会员服务时间为：<strong>{{ vipTime }}</strong>
              <span style="color: #f5303d"
                >（注意：如对方在交往过程中提出财务请求，请提高警惕避免上当！）</span
              >
            </div>
            <!-- 线上会员权益 -->
            <div class="line-line">
              <p class="line"></p>
              <span>线上会员权益</span>
              <p class="line"></p>
            </div>
            <!-- 权益明细 -->
            <div class="definit">
              <div class="definit-list">
                <img src="../../assets/images/slices/deblocking.png" alt="" />
                <p>解锁您的收件箱</p>
              </div>
              <div class="definit-list">
                <img src="../../assets/images/slices/send.png" alt="" />
                <p>解锁消息发送</p>
              </div>
              <div class="definit-list">
                <img src="../../assets/images/slices/badge.png" alt="" />
                <p>尊贵也在会员勋章</p>
              </div>
              <div class="definit-list">
                <img src="../../assets/images/slices/serach.png" alt="" />
                <p>更多精准筛选条件</p>
              </div>
              <div class="definit-list">
                <img src="../../assets/images/slices/upload.png" alt="" />
                <p>无限制上传照片</p>
              </div>
            </div>
            <!-- 开通权益 -->
            <div class="btn" @click="open(shapList[0])">
              立即开通
              <span
                class="price"
                v-for="(item, index) in shapList"
                :key="index"
              >
                <span v-if="index == 0">
                  {{ Number(item.goodPrice) / 100 }}
                  <span class="small">元/年</span></span
                >
              </span>
            </div>
          </div>
          <!-- 服务说明 -->
          <div class="explain">
            <p class="title">服务说明</p>
            <p class="version">
              1、购买的服务仅限本账号使用，不能转移到其他账号;<br />
              2、在使用本服务期间，若从事不恰当或不合法的行为，也在网保留进行调查并立即终止服务的权利;<br />
              3、对于本服务和以上条款，在法律允许范围内也在网拥有解释权。
            </p>
            <p class="title">安全征婚提示</p>
            <p class="version">
              在以下情况下请不要轻易透露你的联系方式(如电话、手机号码、邮箱、即时通信、通信地址等)
              <br />
              1、在未充分了解对方前，请不要轻易透露你的联系方式。<br />
              2、当对方无相片或资料不完整时，请不要轻易透露你的联系方式。<br />
              3、相亲过程中，如遇对方提及借款、投资或索要等行为，请务必提高警惕，谨防受骗，并及时举报。<br />
              4、为保障征婚安全，你需要完成实名人脸认证，才可发送/回复消息。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 付款 -->
    <div class="tankuang" v-if="zhifu">
      <div class="pay">
        <div class="title">
          <p>开通也在会员</p>
          <p class="el-icon-close" @click="close()"></p>
        </div>

        <div class="neirong">
          <p class="name">
            你好,<span class="nickname">{{ name }}</span>
          </p>
          <div class="open">
            开通服务:
            <span class="mount">{{ goodName }}</span>

            <p>
              应支付: <span class="money">{{ price }}元</span>
            </p>
          </div>
          <div>支付方式:</div>
          <div class="zhifu">
            <div
              class="fk"
              v-for="(item, index) in fangshi"
              :key="index"
              @click="pay(index)"
              :class="{ active1: index == ischande }"
            >
              <img :src="item.img" alt="" />
              <p>{{ item.name }}</p>
            </div>
          </div>
          <!-- 支付宝 -->
          <div class="zf" v-show="zfb">
            <div class="erweima">
              <img v-if="!iSzfb" :src="zfbimgUrl" alt="" />
              <img
                v-if="iSzfb"
                style="width: 16px; height: 16px"
                :src="loading"
                alt=""
              />
            </div>
            <div class="point">
              <p class="s">使用支付宝扫码支付</p>
              <p>可支持:</p>
              <p>1.支付宝余额支付</p>
              <p>
                2.支持二十多家主流银行的储蓄卡(即借记卡)和信用卡,无需开通网银,没有支付宝也可支付
              </p>
            </div>
            <div class="gotopay" @click="jumpZf">前往支付</div>
            <iframe
              :srcdoc="templateIframe"
              frameborder="no"
              border="0"
              marginwidth="0"
              marginheight="0"
              scrolling="no"
              width="0"
              height="0"
              style="overflow: hidden"
            >
            </iframe>
          </div>
          <!-- 微信 -->
          <div class="wx" v-show="wx">
            <div class="erweimaw">
              <img v-if="!iSwx" :src="imgUrl" alt="" />
              <img
                v-if="iSwx"
                style="width: 16px; height: 16px"
                :src="loading"
                alt=""
              />
            </div>
            <div class="pointw">
              请使用微信扫一扫<br />
              扫描途中二维码支付
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框是否支付成功   -->
    <div class="istk" v-if="istk">
      <div class="SuccessFailure">
        <div class="title">
          <p>提示</p>
          <p class="el-icon-close" @click="istkClose()"></p>
        </div>
        <!-- 主要内容 -->
        <div class="content">
          <img src="../../assets/images/gold/aq.png" alt="" />
          <p>成功开通会员</p>
        </div>
        <div class="Btn" @click="istkClose()">
          <p>我知道了</p>
        </div>
      </div>
    </div>

    <!-- 支付成功 -->
    <div class="tks" v-if="iSzf">
      <div class="SF">
        <div class="title">
          <p>提示</p>
          <p class="el-icon-close" @click="istkClose()"></p>
        </div>
        <!-- 主要内容 -->
        <div class="content">
          <img src="../../assets/images/gold/ganTao.png" alt="" />
          <p>成功开通会员</p>
        </div>
        <div class="Btn" @click="istkClose()">
          <p>我知道了</p>
        </div>
      </div>
    </div>


    <!-- 判断跳转支付宝支付是否成功 -->
    <div class="tks" v-if="iSpayment">
      <div class="SF">
        <div class="title">
          <p>提示</p>
          <p class="el-icon-close" @click="istkClose()"></p>
        </div>
        <!-- 主要内容 -->
        <div class="content">
          <img src="../../assets/images/gold/ganTao.png" alt="" />
          <p>成功开通会员</p>
        </div>
        <div class="Btn" @click="istkClose()">
          <p>我知道了</p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { infocur } from "@/api/login/login.js";
import {
  Information,
  goodList,
  orderAdd,
  wxorderCreate,
  statusSync,
  midQ,
} from "@/api/my/my.js";
import local from "@/api/common/local.js";
import Swiper from "@/components/Mantle/swiper";
import { ossL } from "@/api/common/oss/ossL.js";
import { subTk } from "@/api/member/member.js";
export default {
  data() {
    return {
      iSpayment:false,
      hi: 0,
      day: ["12", "3", "1"],
      ischande: "",
      fangshi: [
        {
          name: "支付宝",
          img: require("../../assets/images/gold/zhifubao.png"),
        },
        {
          name: "微信",
          img: require("../../assets/images/gold/weixinzhifu.png"),
        },
      ],
      loading: require("@/assets/images/tubiao/loading.gif"),
      zhifu: false,
      wx: false,
      zfb: true,
      tsex: "",
      iSzfb: true, //xxx
      iSwx: true,
      // getcity: "",
      huiyuan: [],
      name: "",
      morequstion: false,
      price: 388,
      goodName: "",
      mounth: 12,
      buyIndex: 0,
      isActive: "",
      shapList: [],
      tuodan: [],
      imgUrl: "",
      zfbimg: "",
      timer: "",
      timerL: "",
      mi: "",
      miL: "",
      templateIframe: null,
      templateIframeJump: null,
      zfbimgUrl: "",
      istk: false,
      orderId: "", //订单id
      iSvip: 0, //判断用户是否是会员
      vipTime: "", //会员时间
      iSzf: false, //支付成功
      // Time:
      clearTime: 10,
      clearTimeLi: 5,
      clearTimeL: 10,
      clearTimeLLi: 5,
    };
  },
  components: {
    Swiper,
  },
  created() {
    this.getactive();
    this.csh();
    this.tkL();
  },
  methods: {
    clickbuy(item, index) {
      console.log("数据", item);
      this.buyIndex = index;
    },
    async open(item) {
      //开启弹框
      this.zhifu = true;
      this.price = item.goodPrice / 100;
      this.goodName = item.goodName;

      let dat = {
        goodId: item.id,
        num: 1,
        orderSource: 1,
      };
      // const _that = this;
      const { code, data } = await orderAdd(dat);
      if (code == 0) {
        this.orderId = data.shopOrderId;
        await this.tkL();
        await this.pay(0);
        //调用接口
        // 微信支付
        let da = {
          orderId: data.shopOrderId,
          payMethod: 0,
          paySource: 1,
        };
        // const _that = this;
        const _that = this;
        const dat = await wxorderCreate(da);
        if (dat.code == 0) {
          _that.iSwx = false;
          _that.imgUrl =
            "https://api.yezai.love/finance-server/finance/order/qrcode/" +
            "?codeUrl=" +
            dat.data.codeUrl;
          // process.env.NODE_ENV == "production"
          //   ? "https://api.yezai.love/finance-server/finance/order/qrcode" +
          //     "?codeUrl=" +
          //     datts.data.codeUrl
          //   : "http://192.168.100.113:8081/finance-server/finance/order/qrcode" +
          //     "?codeUrl=" +
          //     dat.data.codeUrl;
          // process.env.NODE_ENV == "production"
          //   ? "https://api.yezai.love/finance-server/finance/order/qrcode" +
          //     "?codeUrl=" +
          //     datts.data.codeUrl
          //   : "https://api.yezai.love/finance-server/finance/order/qrcode" +
          //     "?codeUrl=" +
          //     dat.data.codeUrl;
          console.log("数据", _that.imgUrl);
          await _that.tkL();
        }

        // 支付宝支付二维码
        let dtt = {
          orderId: data.shopOrderId,
          payMethod: 1,
          paySource: 6,
        };
        const that = _that;
        const datts = await wxorderCreate(dtt);
        if (datts.code == 0) {
          that.iSzfb = false;
          that.zfbimgUrl =
            "https://api.yezai.love/finance-server/finance/order/qrcode/" +
            "?codeUrl=" +
            datts.data.alipay_trade_precreate_response.qr_code;
          // process.env.NODE_ENV == "production"
          //   ? "https://api.yezai.love/finance-server/finance/order/qrcode" +
          //     "?codeUrl=" +
          //     datts.data.alipay_trade_precreate_response.qr_code
          //   : "http://192.168.100.113:8081/finance-server/finance/order/qrcode" +
          //     "?codeUrl=" +
          //     datts.data.alipay_trade_precreate_response.qr_code;
          // console.log("数据", that.zfbimgUrl);
          await that.tkL();
        }

        // 支付宝支付
        let dt = {
          orderId: data.shopOrderId,
          payMethod: 1,
          paySource: 1,
        };
        // const that = _that;
        const dats = await wxorderCreate(dt);
        if (dats.code == 0) {
          that.templateIframeJump = dats.msg;
          await that.tkL();
        }
      }
    },
    //点击跳转支付宝
    jumpZf() {
      // this.iSzf = true;
      this.zhifu = false;
      // this.iSpayment = true;
      // this.timer;
      clearInterval(this.timer);
      clearInterval(this.timerL);
      clearInterval(this.mi);
      clearInterval(this.miL);
      // let rel = this.$router.resolve({
      //   path: "/n/yezaiMember",
      // });
      // window.open(rel.href, "_self");
      //测试
      const that = this;
      setTimeout(() => {
        // this.iSzf = true;
        // this.zhifu = false;
        // window.open("", "_blank");
        that.templateIframeJump = that.insertStr(
          that.templateIframeJump,
          5,
          ` target="_blank" `
        );
        // this.templateIframe = this.templateIframeJump;
        // document.querySelector("body").innerHTML = this.templateIframeJump;  (测试)
        const div = document.createElement("div"); // 创建div
        div.innerHTML = that.templateIframeJump; // 将返回的form 放入div
        document.body.appendChild(div);
        document.forms[0].submit();
      }, 30);

      // // this.iSzf = true;
      // // this.zhifu = false;
      // // window.open("", "_blank");
      // this.templateIframeJump = this.insertStr(
      //   this.templateIframeJump,
      //   5,
      //   ` target="_blank" `
      // );
      // // this.templateIframe = this.templateIframeJump;
      // // document.querySelector("body").innerHTML = this.templateIframeJump;  (测试)
      // const div = document.createElement("div"); // 创建div
      // div.innerHTML = this.templateIframeJump; // 将返回的form 放入div
      // document.body.appendChild(div);
      // document.forms[0].submit();
    },
    insertStr(soure, start, newStr) {
      return soure.slice(0, start) + newStr + soure.slice(start);
    },
    //微信回调
    async statusSync(orderId) {
      //切换
      clearInterval(this.mi);
      clearInterval(this.miL);
      this.clearTimeL = 10;
      this.clearTimeLLi = 5;

      const _that = this;
      this.timer = setInterval(async function () {
        //执行内容
        let a = {
          orderId: orderId,
          payMethod: 0,
          // paySource: 1,
        };
        const { code, data } = await statusSync(a);
        if (code == 0) {
          console.log("数据", data.payStatus);
          if (data.orderStatus == "1") {
            clearInterval(_that.timer);
            _that.zhifu = false;
            _that.istk = true;
          }
        } else {
          // clearInterval(_that.timer);
        }
        await _that.tkL();
      }, 3000);

      const ap = setInterval(function () {
        _that.clearTime = _that.clearTime - 1;
        console.log("数据", _that.clearTime);
        if (_that.clearTime == 0) {
          clearInterval(ap);
          clearInterval(_that.timer);
          _that.clearTime = 10;
        }
      }, 3000);
      //----------
      this.timerL = setInterval(async function () {
        //执行内容
        let a = {
          orderId: orderId,
          payMethod: 0,
          // paySource: 1,
        };
        const { code, data } = await statusSync(a);
        if (code == 0) {
          console.log("数据", data.payStatus);
          if (data.orderStatus == "1") {
            clearInterval(_that.timerL);
            _that.zhifu = false;
            _that.istk = true;
          }
        } else {
          // clearInterval(_that.timer);
        }
        await _that.tkL();
      }, 30000);

      const apL = setInterval(function () {
        _that.clearTimeLi = _that.clearTimeLi - 1;
        console.log("数据", _that.clearTimeLi);
        if (_that.clearTimeLi == 0) {
          clearInterval(apL);
          clearInterval(_that.timerL);
          _that.clearTimeLi = 5;
        }
      }, 30000);
    },

    //支付宝回调
    async alipayCallback(orderId) {
      clearInterval(this.timer);
      clearInterval(this.timerL);
      this.clearTime = 10;
      this.clearTimeLi = 5;

      const _that = this;
      this.mi = setInterval(async function () {
        //执行内容
        let a = {
          orderId: orderId,
          payMethod: 1,
          // paySource: 0,
        };
        // const _that = this;
        const { code, data } = await statusSync(a);
        if (code == 0) {
          console.log("数据", data.payStatus);
          if (data.orderStatus == "1") {
            clearInterval(_that.mi);
            _that.zhifu = false;
            _that.istk = true;
            _that.tk;
          }
        } else {
          // clearInterval(_that.mi);
        }
        await _that.tkL();
      }, 3000);

      const ap = setInterval(function () {
        _that.clearTimeL = _that.clearTimeL - 1;
        console.log("数据", _that.clearTimeL);
        if (_that.clearTimeL == 0) {
          clearInterval(ap);
          clearInterval(_that.mi);
          _that.clearTimeL = 10;
        }
      }, 3000);

      //-------------------
      this.miL = setInterval(async function () {
        //执行内容
        let a = {
          orderId: orderId,
          payMethod: 1,
          // paySource: 0,
        };
        // const _that = this;
        const { code, data } = await statusSync(a);
        if (code == 0) {
          console.log("数据", data.payStatus);
          if (data.orderStatus == "1") {
            clearInterval(_that.miL);
            _that.zhifu = false;
            _that.istk = true;
            _that.tk;
          }
        } else {
          // clearInterval(_that.mi);
        }
        await _that.tkL();
      }, 30000);

      const apLi = setInterval(function () {
        _that.clearTimeLLi = _that.clearTimeLLi - 1;
        console.log("数据", _that.clearTimeLLi);
        if (_that.clearTimeLLi == 0) {
          clearInterval(apLi);
          clearInterval(_that.miL);
          _that.clearTimeLLi = 5;
        }
      }, 30000);
    },

    // 关闭弹框
    close() {
      this.zhifu = false;
      // this.timer;
      clearInterval(this.timer);
      clearInterval(this.timerL);
      clearInterval(this.mi);
      clearInterval(this.miL);
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_self");
    },
    istkClose() {
      this.istk = false;
      let rel = this.$router.resolve({
        path: "/n/yezaiMember",
      });
      window.open(rel.href, "_self");
    },
    //付款微信,支付宝 切换 支付
    pay(index) {
      if (index == 0) {
        this.ischande = 0;
        this.zfb = true;
        this.wx = false;
        this.alipayCallback(this.orderId);
        clearInterval(this.timer);
      } else {
        this.ischande = 1;
        this.zfb = false;
        this.wx = true;
        clearInterval(this.mi);
        this.statusSync(this.orderId);
      }
    },

    async tkL() {
      const { code, data } = await subTk();
      if (code == 0) {
        local.SessionSet("sub_tk", data);
      }
    },
    // 初始化商品
    async csh() {
      this.$store.state.index = 2;
      let dat = {
        categoryId: 1,
      };
      const { code, data } = await goodList(dat);
      if (code == 0) {
        this.shapList = data;
      }
    },
    // 获取当前用户信息
    async getactive() {
      let active = local.get("access_token");
      const { code, data } = await infocur(active);
      if (code == 0) {
        this.iSvip = data.user.isVipMember;
        if (this.iSvip == 1) {
          const dd = {
            memberId: data.user.id,
          };
          const a = await midQ(dd);
          if (a.code == 0) {
            console.log("数据", a.data);
            this.vipTime = `${a.data.startTime}/${a.data.endTime}`;
          }
        }

        // console.log("当前用户信息", data.user);
        this.name = data.user.nickName;
        // this.tsex = data.user.sex;
        if (data.user.sex == 0) {
          this.tsex = 1;
        } else {
          this.tsex = 0;
        }
        let Data = {
          sex: this.tsex,
          // workAddrCityName: this.getcity,
        };
        const z = await Information(Data);
        if (z.code == 0) {
          console.log(z.data.records, "------筛选的数据");
          // this.huiyuan = z.data.records;
          z.data.records.map((item) => {
            this.huiyuan.push(
              Object.assign({}, item, {
                Img: ossL(item.avatar),
              })
            );
          });
        }
      }
    },
    more() {
      this.morequstion = !this.morequstion;
    },
    //停止页面滚动
    noScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); // 禁止页面滑动
    },
    //开启页面滚动
    canScroll() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; // 出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
  },
  //maskOff 换成你的弹窗变量 监听弹窗开启 关闭
  watch: {
    zhifu(newVal) {
      if (newVal) {
        this.noScroll();
      } else {
        this.canScroll();
        this.iSzfb = true;
        this.iSwx = true;
      }
    },
    istk(newVal) {
      // console.log('数据',newVal);
      if (newVal) {
        this.noScroll();
      } else {
        this.canScroll();
      }
    },
    // iSzf(newVal) {
    //   if (newVal) {
    //     this.noScroll();
    //   } else {
    //     this.canScroll();
    //   }
    // },
    // ischande(newVal) {
    //   if (newVal == 0) {
    //     // console.log('数据12');
    //     // this.open()
    //   } else if (newVal == 1) {
    //     console.log("数据");
    //   } else {
    //     console.log("未打开弹框");
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  // height: 1500px;
  // background-image: url("../../assets/images/slices/bg.14e06f9.png");
  background-image: url("../../assets/images/slices/bg.14e06f91.png");
  // background-image: url("../../assets/images/slices/bgci.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background-repeat: repeat-x;
  // background-position-y: 210px;
  // background-position-y: 320px;
  background-color: #fd686e;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  .member {
    // width: 100%;
    width: 1500px;
    height: 1700px;
    // background-size: 100% auto;
    // background-repeat: repeat-x;
    display: flex;
    flex-direction: column;
    align-items: center;

    //-----------------
    //  background-image: url("../../assets/images/slices/bgci.png");
    background-repeat: no-repeat;
    //  background-image: url("../../assets/images/slices/bg.14e06f91.png");
    .content {
      width: 1200px;
      height: 1000px;
      margin-top: 80px;
      display: flex;
      flex-direction: row;
      .left {
        width: 292px;
      }
      .qustion {
        background: #fff;
        border: 1px solid #ebebeb;
        color: #666;
        padding: 14px;
        border-radius: 6px;
        margin-top: 30px;

        .title {
          position: relative;
          font-weight: bold;
          font-size: 16px;
        }
        .title::before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          margin-right: 6px;
          background: 0 0 no-repeat;
          background-size: 100% 100%;
          vertical-align: baseline;
          background-image: url("../../assets/images/tubiao/question.png");
        }
        .title::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          width: 158px;
          border-top: 1px solid #efefef;
        }
        .contentLL.hidden {
          height: 110px;
          overflow: hidden;
        }
        .contentLL {
          height: initial;
          line-height: 17px;
          .question {
            margin-top: 20px;
            font-size: 14px;
          }
          .answer {
            margin-top: 6px;
            font-size: 12px;
            color: #999;
          }
          .key {
            .question {
              margin-top: 20px;
              font-size: 14px;
            }
            .answer {
              margin-top: 6px;
              font-size: 12px;
              color: #999;
            }
          }
        }

        .showMore {
          padding-top: 10px;
          color: #747478;
          text-align: right;
          font-size: 13px;
          cursor: pointer;
        }
      }
      .ta {
        width: 290px;
        // height: 454px;
        border-radius: 8px;
        border: 1px solid #ececec;
        background: #fff;
        margin-top: 20px;
        padding-bottom: 30px;
        .remove {
          width: 290px;
          // text-align: center;
          margin-top: 30px;
          font-size: 16px;
          // font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #666;
          // line-height: 22px;
          padding-left: 20px;
          box-sizing: border-box;
          letter-spacing: 1px;
        }
        div {
          width: 290px;
          height: 80px;
          margin-top: 10px;
          cursor: pointer;
          .ta-avatar {
            width: 76px;
            height: 76px;
            float: left;
            margin-left: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .means {
            width: 162px;
            height: 76px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            float: right;

            margin-right: 20px;
            :first-child {
              font-size: 14px;
              color: #666;
              line-height: 20px;
            }
            .age {
              font-size: 12px;

              color: #999;
              line-height: 17px;

              span {
                // color: #adadad;
                font-size: 12px;

                color: #999;
                line-height: 17px;
              }
            }
            .dubai {
              // color: #666;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 20px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -moz-box;
              -moz-line-clamp: 2;
              -moz-box-orient: vertical;
              word-wrap: break-word;
              word-break: break-all;
              white-space: normal;
            }
            :last-child {
              font-size: 12px;
              color: #666;
              line-height: 17px;
            }
          }
        }
      }
      .honour {
        width: 290px;
        height: 292px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #ececec;
        display: flex;
        flex-direction: column;
        align-items: center;

        .block {
          // width: 218px;
          height: 184px;
          margin-top: 20px;
        }

        .title {
          font-weight: bold;
          font-size: 20px;
          text-align: center;
          line-height: 22px;
        }
        .title .danger {
          color: #f5303d;
        }
        .title::before,
        .title::after {
          content: "";
          display: inline-block;
          width: 24px;
          height: 23px;
          background-repeat: no-repeat;
          background-size: 54px 23px;
          vertical-align: bottom;
          background-image: url("../../assets/images/tubiao/fly.gif");
        }
        .title::before {
          margin-right: 5px;
          background-position: 0 0;
        }
        .title::after {
          margin-left: 5px;
          background-position: -28px 0;
        }
        .list {
          width: 220px;
          height: 200px;
          // height: 200px;
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          background: pink;
          .tdlist {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            .listitem {
              width: 60px;
              height: 90px;
              .avatar {
                width: 56px;
                height: 56px;
                cursor: pointer;
              }
            }
          }

          p {
            width: 60px;
            height: 90px;
            span {
              font-size: 14px;
              color: #555555;
              line-height: 20px;
            }
          }
        }
        p {
          width: 290px;
          font-size: 20px;
          font-weight: bold;
          // font-weight: 500;
          color: #555555;
          line-height: 28px;
          text-align: center;
          margin-top: 18px;
          span {
            color: #f5303d;
            font-weight: 500;
          }
        }
      }
      .right {
        width: 890px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        .explain {
          width: 866px;
          height: 280px;
          margin-top: 20px;
          background: #fff;
          border-radius: 4px;
          border: 1px solid #ececec;
          padding-left: 24px;
          .title {
            font-size: 16px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #666;
            line-height: 22px;
            margin: 14px 0;
          }
          .version {
            width: 803px;
            font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666;
            line-height: 20px;
          }
        }
        .huiyuan {
          width: 890px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          z-index: 20;
          .diamond:hover {
            transform: translateY(-20px);
            transition-duration: 0.5s;
            cursor: pointer;
          }
          // .diamond:hover .detaile>.btn-kt{
          //   background-color: #fff;
          // }
          .diamond {
            border-radius: 12px;
            border: 4px solid #f8df98;
            width: 266px;
            height: 292px;

            border-radius: 12px;
            background: #fff;

            // background: linear-gradient(199deg, #fdeea6 0%, #f6d55e 100%);
            :first-child > img {
              width: 106px;
              height: 106px;
              margin-left: 84px;
              margin-top: -53px;
            }
            .detaile {
              width: 182px;
              height: 196px;
              margin: 0 auto;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #df8200;

              :first-child {
                font-size: 18px;
                line-height: 25px;
              }
              :nth-child(2) {
                margin-top: 20px;
                font-size: 36px;
                line-height: 50px;
                margin-bottom: 8px;
              }
              :nth-child(3) {
                width: 100px;
                height: 1px;
                background: #df8200;
              }
              :nth-child(4) {
                font-size: 14px;
                margin-bottom: 21px;
                margin-top: 9px;
                span {
                  font-size: 32px;
                }
              }
              :nth-child(5) {
                font-size: 16px;
                width: 180px;
                line-height: 46px;

                border-radius: 23px;
                background: #f9eab7;
                cursor: pointer;
              }
            }
          }
          .active {
            background: linear-gradient(199deg, #fdeea6 0%, #f6d55e 100%);
            z-index: 200;
          }
        }
        .mounth {
          background: #fff !important;
          color: red !important;
          :nth-child(5) {
            background: #f9eab7 !important;
            color: #f1ad58;
          }
        }
        .on-line {
          width: 890px;
          height: 500px;
          // margin-left: 50px;
          // margin-top: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background: #fff;
          margin-top: -150px;
          border-radius: 4px;
          border: 1px solid #ececec;
          position: relative;
          //会员期限
          .line-vip {
            position: absolute;
            // top: 160px;
            top: 175px;
            left: 50px;
            color: #333333;
          }
          .btn {
            width: 280px;
            height: 66px;
            border: none;
            background: linear-gradient(270deg, #ffaf2d 0%, #ffc74b 100%);
            border-radius: 33px;
            line-height: 66px;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            color: #ffffff;
            margin: 0 auto;
            margin-bottom: 43px;
            cursor: pointer;
            .price {
              margin-left: 8px;
              font-size: 22px;

              .small {
                font-size: 12px;
              }
            }
          }
          .definit {
            widows: 890px;
            height: 135px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-bottom: 20px;
            .definit-list {
              width: 128px;
              height: 120px;
              img {
                width: 103px;
                height: 103px;
                margin: 0 12px;
              }
              p {
                font-size: 14px;
                text-align: center;
                // font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #666;
                line-height: 22px;
              }
            }
          }
          .line-line {
            margin-left: 45px;
            // margin-top: 170px;
            // margin-top: 180px;
            margin-top: 208px;
            width: 890px;
            .line {
              display: inline-block;
              width: 250px;
              border: 0.5px solid #f1ad58;
              border-radius: 50%;
              background: #f1ad58;
            }
            span {
              margin: 0 80px;
              font-size: 20px;
              color: #df8200;
            }
          }
        }
      }
    }
    .head {
      // position: absolute;
      width: 1250px;
      height: 327px;
      margin-top: 137px;
      display: flex;
      flex-direction: row;
      .kaitong {
        cursor: pointer;
      }
      img {
        width: 456px;
        height: 327px;

        margin-right: 20%;
      }
      div {
        width: 600px;
        height: 280px;
        //   background: red;
        margin-top: 26px;
        :first-child {
          font-size: 50px;
          margin-top: 60px;
          font-weight: bold;
          color: #ffffff;
          line-height: 70px;
          margin-bottom: 10px;
        }
        :nth-child(2) {
          font-size: 30px;

          font-weight: 400;
          color: #ffffff;
          line-height: 42px;
        }
        :last-child {
          width: 184px;
          height: 64px;
          font-size: 22px;
          color: #ffffff;
          line-height: 64px;
          background: linear-gradient(270deg, #ffb83c 0%, #ffe124 100%);
          border-radius: 33px;
          text-align: center;
          margin-top: 23px;
          float: right;
        }
      }
    }
  }

  //支付弹框
  .tankuang {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .pay {
      position: fixed;
      top: 200px;
      left: 40%;
      width: 542px;
      // height: 456px;
      box-sizing: border-box;
      .title {
        display: flex;
        flex-direction: row;
        line-height: 44px;
        background: #f3f3f3;
        text-align: center;
        color: #999;
        font-size: 16px;
        :first-child {
          width: 540px;
          text-align: center;
        }
        /deep/.el-icon-close {
          cursor: pointer;
          margin-right: 10px;
          margin-top: 12px;
        }
      }
      .neirong {
        background: #fff;
        height: 419px;
        padding-left: 20px;
        .name {
          font-size: 16px;
          padding: 20px 0 10px 0;
          span {
            color: black;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .open {
          display: flex;
          flex-direction: row;
          line-height: 40px;
          .mount {
            line-height: 40px;
            font-weight: bold;
            margin-right: 200px;
          }
          p {
            .money {
              color: red;
              font-size: 30px;
            }
          }
        }
        .wx {
          width: 500px;
          height: 120px;
          display: flex;
          flex-direction: row;
          // margin-top: 18px;
          margin-top: 30px;
          .erweimaw {
            width: 120px;
            height: 120px;
            margin-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              // width: 100%;
              width: 120px;
              height: 120px;
              // position: absolute;
            }
          }
          .pointw {
            color: #1d1d1d;
            margin-top: 40px;
            margin-left: 20px;
            line-height: 20px;
            font-size: 14px;
          }
        }
        .zf {
          margin-top: 30px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          height: 180px;
          position: relative;
          .gotopay {
            cursor: pointer;
            font-size: 18px;
            width: 157px;
            height: 46px;
            line-height: 46px;
            background: #4987e8;
            color: #fff;
            text-align: center;
            margin-top: 26px;
            border-radius: 4px;
          }
          .erweima {
            width: 120px;
            height: 120px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background: url('../../assets/images/tubiao/loading.gif') no-repeat center;
            img {
              margin-left: 10px;
              width: 114px;
              height: 114px;
            }
          }
          .point {
            color: #666;
            width: 314px;
            .s {
              font-size: 14px;
              color: #333333;
              line-height: 20px;
              margin-bottom: 8px;
            }
            p {
              // width: 311px;
              // height: 68px;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              line-height: 17px;
            }
          }
        }
      }
      .zhifu {
        display: flex;
        flex-direction: row;
        .fk {
          width: 141px;
          height: 41px;
          display: flex;
          justify-content: center;
          align-items: center;
          // text-align: center;
          // line-height: 41px;
          border: 1px solid #dfdfdf;
          margin: 10px 10px 0 0;
          cursor: pointer;
          img {
            margin-right: 10px;
          }
        }
        .active1 {
          border: 1px solid #4987e8;
        }
        .name {
          font-size: 16px;
          padding: 20px 0 10px 0;
          span {
            color: black;
            font-size: 18px;
            font-weight: bold;
          }
        }
      }
    }
  }
  //是否支付成功
  .istk {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .SuccessFailure {
      position: fixed;
      top: 200px;
      left: 40%;
      width: 420px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        line-height: 44px;
        background: #f3f3f3;
        text-align: center;
        color: #999;
        font-size: 16px;
        width: 420px;
        position: relative;
        /deep/.el-icon-close {
          position: absolute;
          right: 10px;
          top: 12px;
          cursor: pointer;
          // margin-right: 10px;
          // margin-top: 12px;
        }
      }
      .content {
        padding-top: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          margin-top: 16px;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .Btn {
        width: 180px;
        height: 44px;
        margin-top: 24px;
        margin-bottom: 20px;
        background-color: #fd686e;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  //支付
  //是否支付成功
  .tks {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .SF {
      position: fixed;
      top: 200px;
      left: 40%;
      width: 420px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        line-height: 44px;
        background: #f3f3f3;
        text-align: center;
        color: #999;
        font-size: 16px;
        width: 420px;
        position: relative;
        /deep/.el-icon-close {
          position: absolute;
          right: 10px;
          top: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
