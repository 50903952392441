<template>
  <div>
    <div class="tip">
      <el-carousel>
        <el-carousel-item v-for="item in result" :key="item.id">
          <!-- v-for="item in result" :key="item.id" -->
          <ul ref="list">
            <li v-for="i in item" :key="i.id">
              <div class="a">
                <!-- @click="jump(i)" -->
                <img :src="i.htImg || src" alt="" />
                <span style="font-size: 14px">{{ i.nickName }}</span>
              </div>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { desinglelist } from "@/api/member/member.js";
import { ossL } from "@/api/common/oss/ossL.js";
export default {
  data() {
    return {
      data: [],
      result: [],
      src: require("../../assets/images/message/tou.png"),
      pageNum: 1,
      pages: "",
    };
  },
  created() {
    this.desinglelist();
  },
  mounted() {
    this.fn();
  },
  methods: {
    async desinglelist() {
      this.data = [];
      const { code, data } = await desinglelist();
      if (code == 0) {
        data.records.map(async (item) => {
          this.data.push(
            Object.assign({}, item, {
              htImg: ossL(item.avatar),
            })
          );
        });
        this.pages = data.pages;
        let chunk = 6;
        for (let i = 0; i < this.data.length; i += chunk) {
          this.result.push(this.data.slice(i, i + chunk));
        }
      }
    },
    fn() {
      // console.log("000", (this.$refs.list.style.top = -174 + "px"));
      // setInterval(()=>{
      //   this.$refs.list.top = 174 + 'px'
      // },3000)
    },
    jump(item) {
      let rel = this.$router.resolve({
        path: "/u",
        query: { id: item.id },
      });
      window.open(rel.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  // position: relative;
  width: 243px;
  // height: 174px;
  height: 188px;
  z-index: 999;
  /deep/ .is-active {
    display: flex;
    width: 243px;
    flex-wrap: wrap;
  }
  /deep/ .is-animating {
    display: flex;
    width: 243px;
    flex-wrap: wrap;
  }
  /deep/ .el-carousel__item {
    width: 243px;
    display: flex;
    flex-wrap: wrap;
  }
  /deep/ .el-carousel__indicator--horizontal {
    display: none;
  }
  /deep/ .el-carousel--horizontal:hover .el-carousel__arrow {
    display: none;
  }
  ul {
    width: 243px;
    // height: 174px;
    height: 188px;
    // width: 100%;
    // height: 100%;
    overflow: hidden;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    // position: absolute;
    li {
      display: flex;
      // flex-direction: column;
      margin: 0px 10px;
      // width: 54px;
      margin-top: 15px;
      .a {
        width: 54px;
        // display: flex;
        // flex-direction: column;
        // overflow: hidden;
        img {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          cursor: pointer;
        }
        span {
          display: -webkit-box !important;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>